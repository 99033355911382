import React, { useEffect, useState } from "react"
import { ThemeProvider } from "@material-ui/styles"
import { CssBaseline, NoSsr } from "@material-ui/core"
import { IntlProvider, addLocaleData } from "react-intl"
import { client } from "../services/Moltin"
import { graphql } from "gatsby"
import { Location } from "@reach/router"
import moltinThreshold from "../../../constants/moltinThreshold"
import CtaParser from "../../../utilities/marketingCtaParser"

import {
  Header,
  Footer,
  CartProvider,
  MarketingCTA,
  HeroBanner,
  ProductLine,
  SEO,
  MobileOrientation,
} from "../../../components"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import Theme from "../theme"

import LeafLeft from "../images/leaf left.png"
import LeafRight from "../images/leaf right.png"

import locale_es from "react-intl/locale-data/es"
import locale_en from "react-intl/locale-data/en"
import PuraEarthSymbol from "../images/pura earth symbol background.svg"

addLocaleData([...locale_en, ...locale_es])

const localeToCurrencyConsts = require("../../../constants/localeCurrencyConsts")
const localeConsts = require("../../../constants/localeConsts")

const useName = (product, header) => {
  const [result, setResult] = useState(product.name)
  useEffect(() => {
    ;(async () => {
      const name = await client
        .get(`products/${product.id}`, header)
        .then(product => product.data.name)
      setResult(name)
    })()
  }, [])
  return result
}

const usePrice = (product, header) => {
  const [result, setResult] = useState("Loading...")
  useEffect(() => {
    ;(async () => {
      const amount = await client
        .get(`products/${product.id}`, header)
        .then(product => product.data.meta.display_price.without_tax.formatted)
      setResult(amount)
    })()
  }, [])
  return result
}

const useAvailable = (productInfo) => {
  let productMap = new Map ()
  const [available, setAvailable] = useState(productMap)
  useEffect(()=>{
    for(let product of productInfo){
      const amount = client
          .get(`inventories/${product.id}`)
          .then(result => productMap.set(result.data.id, result.data.available))
    }},[])
  return available
}

export default ({
  pageContext: { productLine, footer, headerData, locale, slug },
  location,
}) => {
  const langKey = locale
  let header = {
    "X-Moltin-Language": localeConsts.moltinLanguageCode[locale],
    "X-Moltin-Currency": localeToCurrencyConsts[locale],
  }
  const i18nmessages = require(`../messages/index/${
    langKey === "es" ? localeConsts.es : localeConsts.en
  }`)
  const pathPrefix = `${langKey + productLine.slug}`
  const newPath =
    pathPrefix + (langKey === "es" ? localeConsts.esPath : localeConsts.enPath)

  const slides = productLine.heroBannerSlides
  const heroBannerMediaPosition = productLine.herobannerMediaPosition
  const heroBannerColor = productLine.heroBannerColor

  const slideData = []

  for (let slide of slides) {
    let slideObject = {
      titleText: slide.richTitle,
      copyText: slide.richDescription,
      imageSrc: slide.image.fluid,
      videoSrc: slide.image.file.url,
      staticImg: slide.image.file.url,
      mediaType: slide.mediaType,
      color: heroBannerColor ? heroBannerColor : productLine.color,
      mediaPosition: heroBannerMediaPosition,
      static: true,
    }
    slideData.push(slideObject)
  }
  const otherProds = []
  for (const product of productLine.otherProductLines) {
    otherProds.push({
      ...product,
      title: product.productLineTitle,
      buttonText: product.cardButtonText,
      imageSrc: product.image.localFile.childImageSharp.fixed.src,
      isProductLine: true,
      langKey: langKey,
    })
  }

  let prodCards = []
  const availProd = useAvailable(productLine.productCategories)
  for (let product of productLine.productCategories) {
    let data = {
      contentful_id: product.id,
      buttonText: "discover",
      color: productLine.color,
      btnColor: "white",
      // eslint-disable-next-line
      title: useName(product, header),
      slug: product.slug,
      image: product.file.link.href,
      imageSrc: product.file.link.href,
      // eslint-disable-next-line
      price: usePrice(product, header),
      productTitle: product.name,
      langKey: langKey,
      itemId: product.id,
      isOutOfStock:
        // product.inventories.data.available <=
          availProd.get(product.id) <=
        parseInt(moltinThreshold.thresholdValue),
      isComingSoon: product.isComingSoon,
    }
    prodCards.push(data)
  }

  const otherProductLines = {
    titleText: productLine.otherProductLinesTitle,
    items: otherProds,
  }

  const productCards = {
    titleText: "",
    items: prodCards,
  }

  const marketingCtaData = []

  if (productLine.marketingCta) {
    for (let mCta of productLine.marketingCta) {
      let mCtaObject = {
        richTitle: mCta.richTitle,
        richDescription: mCta.richDescription,
        picture: mCta.picture,
        mediaVimeoUrl: mCta.mediaVimeoUrl,
        mediaType: mCta.mediaType,
        marketingCtaType: mCta.marketingCtaType,
        orderNumber: mCta.orderNumber,
        contentPosition: mCta.contentPosition,
        buttonColor: mCta.buttonColor ? mCta.buttonColor : productLine.color,
        buttonText: mCta.buttonText,
        redirectLink: `${langKey}/${mCta.redirectLink}`,
        color: mCta.backgroundColor ? mCta.backgroundColor : productLine.color,
        mediaPosition: mCta.mediaPosition,
        backdropSrc: PuraEarthSymbol,
        hideBackground:
          mCta.backgroundImage !== null && !mCta.backgroundImage ? true : false,
        productPage: true,
      }
      marketingCtaData.push(mCtaObject)
    }
  }

  const pageMetadata = {
    title: productLine.metadataTitle,
    description: productLine.metadataDescription,
    url: location.href,
  }

  return (
    <>
      <MobileOrientation />
      <SEO siteMetadata={pageMetadata} />
      <CssBaseline />
      <IntlProvider locale={langKey} messages={i18nmessages}>
        <ThemeProvider theme={Theme}>
          {/* <AuthWrapper> */}
          <CartProvider>
            <Location>
              {({ location }) => {
                if (newPath !== location.pathname) {
                  location.href = newPath
                }
              }}
            </Location>
            <Header
              data={
                langKey === localeConsts.es
                  ? headerData[1].node
                  : headerData[0].node
              }
              slug={slug}
            />
            {slideData && <HeroBanner slideData={slideData} isProductLine />}
            <ProductLine
              productLineData={productCards}
              isSingleProduct
              productPage
              langKey={langKey}
            />
            {marketingCtaData.length !== 0 && (
              <CtaParser data={marketingCtaData} />
            )}
            <ProductLine
              productPage
              productLineData={otherProductLines}
              langKey={langKey}
            />
            <Footer
              data={
                langKey === localeConsts.es ? footer[1].node : footer[0].node
              }
              langKey={langKey}
              bgImgLeft={LeafLeft}
              bgImgRight={LeafRight}
            />
          </CartProvider>
          {/* </AuthWrapper> */}
        </ThemeProvider>
      </IntlProvider>
    </>
  )
}
