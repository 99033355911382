import React from "react"
import {
  AvcnStndsMarketingCta,
  MarketingCTA,
  CenteredMarketingCTA,
} from "components"
import PuraEarthSymbol from "../images/pura_earth_symbol_background.svg"
const MCtaParsor = props => {
  return (
    <React.Fragment>
      {props.data.map((cta, i) => {
        let ctaObject = {
          titleText: cta.richTitle,
          copyText: cta.richDescription,
          imageSrc: cta.picture
            ? cta.picture.fluid
              ? cta.picture.fluid
              : cta.picture.file.url
            : null,
          videoSrc: cta.picture ? cta.picture.file.url : null,
          vimeoSrc: cta.mediaVimeoUrl,
          mediaType: cta.mediaType,
          buttonText: cta.buttonText,
          buttonColor: cta.buttonColor,
          redirectLink: cta.redirectLink,
          color: cta.color,
          productPage: cta.productPage ? cta.productPage : false,
          hideBackground: cta.hideBackground,
        }
        return cta.marketingCtaType === "Ordered" ? (
          <AvcnStndsMarketingCta
            key={i}
            num={cta.orderNumber}
            // hideBackground
            reversed={cta.mediaPosition === "Right" ? false : true}
            {...ctaObject}
            backdropSrc={PuraEarthSymbol}
            left
          />
        ) : cta.contentPosition === "Centered" ? (
          <CenteredMarketingCTA
            key={i}
            static={true}
            reversed
            // hideBackground
            backdropSrc={PuraEarthSymbol}
            removeBottomPadding={true}
            {...ctaObject}
          />
        ) : (
          <MarketingCTA
            key={i}
            reversed={cta.mediaPosition === "Right" ? false : true}
            backdropSrc={PuraEarthSymbol}
            {...ctaObject}
          />
        )
      })}
    </React.Fragment>
  )
}

export default MCtaParsor
